<template>
  <div class="page-box">
    <base-screen>
      <a-form-model layout="inline" :model="query">
        <template v-for="f in report.queryFields">
          <a-form-model-item v-if="!f.hidden" :key="f.key" :label="f.title" :prop="f.key">
            <search-field v-if="f.type === 'search'" :f="f" v-model="query[f.key]"></search-field>
            <a-input v-else-if="f.type === 'input'" :placeholder="f.placeholder" v-model="query[f.key]"></a-input>
            <date-range-field v-else-if="f.type === 'daterange'" v-model="query[f.key]" :f="f"></date-range-field>
            <select-field v-else-if="f.type === 'select'" v-model="query[f.key]" :f="f"></select-field>
          </a-form-model-item>
        </template>
        <a-form-model-item>
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>

    <div class="tr" style="padding-bottom: 15px;">
      <a-button type="primary" @click="$router.push('/capital/invoice/service/request')">申请开票</a-button>
    </div>

    <base-table
      :columns="report.resultFieldsPlain"
      :row-key="report.key"
      :data-source="data"
      :loading="loading"
      :pagination="page"
      @change="handleTableChange"
      :scroll="{x: 'auto'}"
      class="nowrap"
    >
      <template #operation="_, record">
        <a v-if="record.invoicePic" class="link" :href="$res + record.invoicePic" download="发票">下载发票</a>
      </template>
    </base-table>
  </div>
</template>

<script>
import { capital } from '@/api'
import { Report } from './report'
import { onMounted, ref, shallowReactive, watch } from '@vue/composition-api'
import SearchField from './SearchField'
import DateRangeField from './DateRangeField'
import SelectField from './SelectField'
import { serviceInvoiceStates, invoiceTypes } from '@/utils/constants'
import { createMapFromList } from '@/utils/tools'

export default {
  name: 'PageInvoiceService',
  components: {
    SearchField,
    DateRangeField,
    SelectField,
  },
  setup () {
    const queryFields = [
      {
        type: 'daterange',
        key: 'range',
        title: '申请时间',
      },
    ]

    const resultFields = [
      {
        key: 'applyTime',
        title: '申请时间',
      },
      {
        key: 'invoiceTitle',
        title: '发票抬头',
      },
      {
        key: 'taxpayerNumber',
        title: '纳税人识别号',
      },
      {
        key: 'registerAddr',
        title: '注册地址',
      },
      {
        key: 'registerPhone',
        title: '注册电话',
      },
      {
        key: 'openBank',
        title: '开户银行',
      },
      {
        key: 'bankCardNo',
        title: '银行账号',
      },
      {
        key: 'invoiceAmount',
        title: '开票金额',
      },
      {
        key: 'email',
        title: '邮箱',
      },
      {
        key: 'invoiceState',
        title: '开票状态',
        transform: x => createMapFromList(serviceInvoiceStates)[x]
      },
      {
        key: 'operation',
        title: '操作',
        scopeFlag: true,
        fixed: 'right',
      }
    ]

    const report = new Report(queryFields, resultFields, {
      key: 'invoiceRecordId',
      queryApi: ({ range, ...others }) => {
        return capital.getInvoiceServiceRequestPage({
          ...others,
          ...(range.length ? {
            applyBeginTime: range[0].format('YYYY-MM-DD 00:00:00'),
            applyEndTime: range[1].format('YYYY-MM-DD 23:59:59')
          } : null),
        })
      }
    })

    const query = ref(report.initialQuery)
    const data = ref([])
    const loading = ref(false)
    const page = shallowReactive({
      current: 1,
      size: 10,
      total: 0
    })

    onMounted(handleQuery)

    async function handleQuery () {
      loading.value = true
      const res = await report.query({
        ...query.value,
        current: page.current,
        size: page.size,
      })
      loading.value = false
      data.value = res.data
      page.total = res.page.total
    }

    function handleReset () {
      query.value = report.initialQuery
      handleQuery()
    }

    function handleTableChange ({ current }) {
      page.current = current
    }

    watch(() => page.current, value => {
      handleQuery()
    })

    return {
      report,
      query,
      handleQuery,
      handleReset,
      loading,
      data,
      page,
      handleTableChange,
      $res: process.env.VUE_APP_IMG,
    }
  }
}
</script>

<style lang="less" scoped>
.link.link.link {
  color: @main-color;
}
</style>
